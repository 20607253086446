@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  font-family: roboto, Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif;
 }



.redcheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  /*margin-bottom: 12px;*/
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.redcheckbox input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

}

/* Create a custom checkbox */
.redcheckbox .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;

  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.redcheckbox:hover input ~ .checkmark {
  background-color: #FFF;
}

/* When the checkbox is checked, add a blue background */
.redcheckbox input[type="checkbox"] ~ .checkmark {
  border: 2px #DDDDDD solid;
}

.redcheckbox input[type="checkbox"]:checked ~ .checkmark {
  background-color: #F88A2A;
  border: 2px #F88A2A solid;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.redcheckbox input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.redcheckbox .checkmark:after {
  left: 2px;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}


/* Change the icon to a down arrow */
.custom-select::-ms-expand {
  display: none;
}
.custom-select.select::-ms-expand {
  display: inline-block;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white'  %3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 20px 20px;
  padding-left: 40px;


}


.custom-calendar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-linecap='round' stroke-width='1.5' stroke='black'  %3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z' /%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 20px 20px;



}
